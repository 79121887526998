
<template>

    <div class="cell large-10" style="text-align:left; padding:3px">

        <template v-if="view === 'list'">

           <div class="grid-x qmediumfont" >
            <div class="cell small-7">
            <div class="button-group">
                <button type="button" class="button small" v-on:click="gradeBets">{{ langConv('Grade All') }}</button>
                <button type="button" class="button small" v-on:click="deleteBet">{{ langConv('Delete') }}</button>
                <button type="button" class="button success" v-on:click="getBets()">{{ langConv('Go') }}</button>
            </div>
            </div>

            <div class="cell small-5">
                <span>From <datepicker class="calwidth" v-model="fromdate" :clearable="false" :editable="false" ></datepicker></span>
                <span>To <datepicker class="calwidth" v-model="todate" :clearable="false" :editable="false"></datepicker></span>
            </div>

            <div class="cell grid-container">
                <div class="grid-x grid-margin-x">
                <div class="cell small-1 align-self-middle">
                    {{ langConv('User') }}
                </div>
                    <div class="cell small-2 align-self-middle">
                        <select v-model="searchuser" >
                            <option v-for="(user,id) in users" :key="id">
                                {{user.D}}
                            </option>

                        </select>
                    </div>
                    <div class="cell small-6 align-self-middle"><autocomplete :search="searchTitle" :get-result-value="getResultValue" :placeholder="langConv('find teams, competitions and more')" @submit="setSearchValue"></autocomplete></div>
                    <div class="cell small-1 align-self-middle">
                        {{ langConv('Outcome')}}
                    </div>
                    <div class="cell small-2 align-self-middle">
                        <select v-model="searchwinorlose">
                            <option selected>All</option>
                            <option>Win</option>
                            <option>Lose</option>
                            <option>Pending</option>
                        </select>
                    </div>


                </div>
                </div>
            </div>


            <div class="grid-x" style="font-size:small">
                <div class="cell small-2">{{ langConv('Bet Place Date') }}</div>
                <div class="cell small-1">{{ langConv('User') }}</div>
                <div class="cell small-6">{{ langConv('Bet Title') }}</div>
                <div class="cell small-1">{{ langConv('Grading') }}</div>
                <div class="cell small-2">{{ langConv('Last Edited') }}</div>
            </div>
            <div>
                <div class="grid-y">
                    <div v-for="(bh,id) in betheaders" @dblclick="openBetResults(bh.GUID,bh.Title)" @click="selectRow(bh.GUID,id)" :key="bh.GUID" :class="{'highlight': (bh.GUID == selectedGUID)}">
                        <div class="grid-x" style="font-size:small">
                            <div class="cell small-2">{{bh.Created}}</div>
                            <div class="cell small-1">{{bh.name}}</div>
                            <div class="cell small-6">{{bh.Title}}</div>
                            <div class="cell small-1" :style="{color: valueColour(bh.OverallGrading)}">{{bh.OverallGrading}}</div>
                            <div class="cell small-2">{{bh.Updated}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </template>


        <template v-if="view === 'pendingbetsonly'">

            <div  >
            <div class="grid-x margin-x" >

            <h1 class="cell qmajorheader">{{ langConv('Ungraded bets') }}</h1>
            <h2 class="cell small- 12 qsubheader text-center">{{ langConv('All the outstanding OG bets. Real bets, no tips, all following a strategy') }}</h2>
            </div>
            <div class="grid-x text-center qtableheader" style="margin-top: 10px">
                <div class="cell small-7 qsmallfont">{{langConv('Bet Title')}}</div>
                <div class="cell small-2 qsmallfont">{{langConv('Contains Freebets') }}</div>
                <div class="cell small-2 qsmallfont">{{langConv('Bet Strategy') }}</div>
                

            </div>
            <div>
                <ul style="margin-left: 0px">
                    <li style="display: inline" v-for="(pr,i) in pendingresults" :key="i" >
                      
                        <template v-if="i==0 || (i>0 && pendingresults[i-1].Title!=pr.Title)">
                          
                            <div class="grid-x qsmallfont" style="border-bottom: 2px  #89CFF0 solid;background-color: white;height:30px;padding-top:5px">
                                <div class="cell small-7 text-center">{{removeNumber(pr.Title)}}</div>
                                <div class="cell small-2 text-center" v-bind:class="[{ tick: bVal(pr.FreeBet)}]"></div>
                                <div class="cell small-2 text-center ">{{langConv(removeNumber(pr.betstrategy))}}</div>
                                <div class="cell small-1" @click="setShowResults(pr.GUID)"    >
                                    <div :class="{uprotate: showresults==i, downrotate: showresults!=i }"  style="font-size:12px">&#9660;</div>
                                </div>
                            </div>
                            <template v-if="showresults==pr.GUID">
                            <div style="height:5px"></div>
                            <span class="qsmallfontplus qbold">
                                &nbsp;&nbsp;Bets placed:
                            </span>
                        </template>
                         
                        </template>
                        
                        
                        <template v-if="showresults == pr.GUID">
                            <span v-if="pr.bookmaker !== null">
                                   <a :href="'/bookmakerloader.html?bkm=' + pr.bookamker + '&country=' + $store.state.countryname" target="_blank"><span v-bind:class="'b' + pr.bookmaker"></span></a>     
                            </span> 
                            <span class="qsmallfontplus qdarkgrey qsubheader" >{{removeNumber(pr.BetDesc)}} @ {{(parseFloat(removeNumber(pr.Odds)).toFixed(2))}}&nbsp;&nbsp;&nbsp;</span> 
                        </template>
                    </li>
                </ul>
            </div>
            </div>



        </template>


            <modal name="hutchdutchsettlementmodal" :adaptive="true" :minHeight="740">
                <div style="padding:10px;">
                    <h3 style="display:inline-block">Bet Games Settlement</h3>
                    <div style="float:right" class="button small" v-on:click="hidePopUp('hutchdutchsettlementmodal', false)">X</div>
                    <div class="grid-x">
                        <div class="cell small-10"> Bet Title : {{selectedbet}}</div>
                        <div class="cell small-2"><button class="button small" v-on:click="saveResults()">Save</button>      </div>
                    </div>
                    <div class="grid-x" style="font-size:x-small; text-align: center">
                        <div class="cell small-2">GUID</div>
                        <div class="cell small-1">Created</div>
                        <div class="cell small-5">Title</div>
                        <div class="cell small-1">Outcome 1</div>
                        <div class="cell small-1">Outcome 2</div>
                        <div class="cell small-2">Winner</div>
                    </div>
                    <hr />
                        <div v-for="(result, id) in betresults"  :key="id" @click="storeSettlementRow(id)">
                            <div class="grid-x" style="font-size:x-small" @focus="test(id)" v-if="betresults.length > 0" >
                                <div class="cell small-2" style="font-size:xx-small">{{result.GUID}}</div>
                                <div class="cell small-1" style="font-size:xx-small">{{$displayDate(result.Created,$dateformattype.daymonthtime)}}</div>
                                <div class="cell small-5">{{result.Title}}</div>
                                <div class="cell small-1"><input v-model="result.Score1" size="3" /></div>
                                <div class="cell small-1"><input v-model="result.Score2" size="3" /></div>
                                <div class="cell small-2">
                                    <select v-model="result.Winner">
                                            <option  v-for="(option,i) in winneroutcomes" :key=i :value=option.description>{{ option.description }}</option>
                                    </select>


                                    <!-- <options >  
                                        <template v-slot:box1>Yes</template>
                                        <template v-slot:box2>No</template>
                                        <template v-slot:box3>TBD</template>
                                    </options> -->
                                </div>
                            </div>

                        </div>

                </div>
            </modal>

        <!-- <footer class="cell footer">
            Trotmansoftware {{ new Date().getFullYear() }}
        </footer> -->

    </div>


</template>

<script>

    import datepicker from 'vue2-datepicker';
    import Autocomplete from '@trevoreyre/autocomplete-vue'
    import axios from 'axios'
    import 'vue2-datepicker/index.css';
   // import options from '../components/options.vue'

    export default {
        name: "hutchdutchsettlement",
        data() {
            return {
                betheaders: [],
                users: [],
                selectedGUID: '',
                selectedRowID: 0,
                errors: [],
                fromdate: "1/1/1970",
                todate: "1/1/2099",
                searchuser: 'All',
                searchval: '',
                searchwinorlose: 'All',
                searchmanualorauto:  'All',
                focused: false,
                betresults: [],
                selectedbet: '',
                betresultid: 0,
                view: 'list',
                pendingresults: [],
                winneroutcomes: [],
                showresults: -1

            }
        },

        components: {
            datepicker,
            Autocomplete
        },

        computed: {
            noResults() {
                return this.value && this.results.length === 0
            }
        },
        created() {

            //this is a flag so can configure the bet settlement page
            if (window.location.href.includes("PendingBets")) {
                this.view = 'pendingbetsonly'
                this.getPending();
            } else {

                this.defaultdates();

                this.users = this.$store.state.users
            }

            

        },

        methods: {

            setShowResults: function(val) {

                if (this.showresults == val) {
                    this.showresults = -1;
                } else {
                    this.showresults = val;
                }
            },


            bVal: function(val) {

                if (val == '1' || val == 1) {return true} else {return false}
            },

            //remove numbers get rid of  ~
            removeNumber: function(val) {
                try {

                if (val === 'undefined') {
                    return val
                } else {

                if(val!="") {
                    if (val.length > 1) {
                        const reg = /\d./g
                        val = val.replaceAll("~","");
                        val = val.replace(reg, "");
                        val = val.replace(/\(.*/,'');
                    }
                }


            }
                return val;
                } catch (error) {
                    console.log(error);
                }

            },

        //set default dates for the date selection
            defaultdates: function() {

                    let d = new Date()
                    this.todate = new Date(d.setDate(d.getDate()+1));
                    this.fromdate = new Date(d.setDate(d.getDate() - 180)); //last 7 days entries by default

            },


            // pop up functionality
            hidePopUp: function (val, reload) {

                if (reload === undefined) reload = true;

                this.$modal.hide(val);

                //update screen (reload for mo?)
                if (reload === true) location.reload(false);
            },


            //save bet results (from popup)
            saveResults: function () {

                    axios.post(process.env.VUE_APP_WEBAPI  + "SaveRetrieveandDeleteData.svc/SaveBetResults",
                    {
                        jsonString: JSON.stringify(this.betresults)
                    }).then(response => {
                        alert("Bet Updated")
                    },
                        (error) => {alert("Error Updating Bet Message")}
                    );

            },



            //create the entries that appear in the drop down boxes
            deleteSelectedBet(selectedbetno) {

                this.selectedbets = this.selectedbets.filter(function (values) {
                    return values.betno != selectedbetno
                });

                this.defineBetNames();
            },

            //bet history sleect row function
            selectRow: function (GUID, id) {

                this.selectedGUID = GUID;
                this.selectedRowID = id

            },

            gradeBets: function () {

                let SP = "GradeBets_NS";

                let params = { SP };
                let url = process.env.VUE_APP_WEBAPI  + "SaveRetrieveandDeleteData.svc/SimpleSPExecute"

                //get bet results for that bet
                this.genericpost(url, params).then(response => {
                       alert("Bets with scores have been Graded")
                },
                        (error) => { console.log(error) }
                );


            },

                winnerDropdown: function (GUID) {


                let url = process.env.VUE_APP_WEBAPI  + "FeatureTables.svc/RetrieveGeneric";
                let spname = "WinnersforEvent_NS";
                let params = '[{ "Name": "@guid", "Value": "' +  GUID + '"}]';
           

                

                //get bet results for that bet
                this.newgenericresultset(url, params,spname).then(response => { this.winneroutcomes = response},
                        (error) => { console.log(error) }
                );



            },

             getPending: function () {

                let params = '[]';
                let url = process.env.VUE_APP_WEBAPI  + "FeatureTables.svc/RetrieveGeneric";
                let spname = "GetPendingBets_NS";

                //get bet results for that bet
                this.newgenericresultset(url, params,spname).then(response => { this.pendingresults = response});

             },

            getBets: function () {

                //retrieve bet headers for that bet
               axios.get(process.env.VUE_APP_WEBAPI  + "FeatureTables.svc/GetBetHeaders", {

                    params: {
                        noitems: 999,
                        country: this.$store.state.countryname,
                        startdate: this.fromdate,
                        enddate: this.todate,
                        searchval: this.searchval,
                        searchuser: this.searchuser,
                        searchwinorlose: this.searchwinorlose
                    }

                }).then(response => {

                    //check if anything in the response
                    if (response.data != '') {//{ throw new Error('There is are no bets for the selected criteria')}

                    this.betheaders = JSON.parse(response.data);

                    this.selectedGUID = this.betheaders[0].GUID;
                    } else {
                         this.betheaders = [];
                    }

                },
                (error) =>
                //when throw "Error" is executed it runs the catch block code

                {

                    //clear any exist search results
                    this.betheaders = [];
                    let specerror = "`" + error.message + "`"
                    alert(specerror);
                } );

            },

            deleteBet: function () {

                //delete selected GUID entries from  betheader and betdetails
                axios.delete(process.env.VUE_APP_WEBAPI  + "SaveRetrieveandDeleteData.svc/SimpleDelete", {
                    data: {
                        Table: 'BetHeaders_NS',
                        Where: 'GUID = ' + '\'' + this.selectedGUID + '\''
                    }
                }).then(response => {

                    alert(`Bet Deleted Successfully`);
                    this.getBets();
                },

                    (error) => { console.log('error deleting Bet Header - ' + error) }

                );

            },

            //activated window on double click of line
            openBetResults: function (GUID, Title) {

                this.winnerDropdown(GUID);

                this.betresults = [];
                this.selectedbet = Title;

                let params = { GUID, Title };
                let url = process.env.VUE_APP_WEBAPI  + "FeatureTables.svc/RetrieveBetResults"



                //get bet results for that bet
                this.genericresultset(url, params).then(response => { this.betresults = response, this.$modal.show('hutchdutchsettlementmodal')});


            },

            openAllBetResults: function () {

                this.betresults = [];

                let params = { GUID, Title };
                let url = process.env.VUE_APP_WEBAPI  + "FeatureTables.svc/RetrieveAllBetResults"

                //get bet results for that bet
                this.genericresultset(url, params).then(response => { this.betresults = response });


            },

            //for settlement popup
            defineBetNames: function () {

                var self = this;

                //clear down the bet options before recreating
                self.betoptions = [];

                var arrayLength = this.selectedbets.length;
                for (var iloop = 0; iloop < arrayLength; iloop++) {

                    //need to iterate through all autocomplete values on the form

                    //Is the event tpo or OR
                    if (self.selectedbets[iloop].betname.includes(" v ")) {

                        var betnames = self.strip_html_tags(this.selectedbets[iloop].betname).split(" v ");

                        var bettype = self.selectedbets[iloop].bettype;

                        //Remove Teams - Over Under Goals / Double Chance / Odd or Even
                        if (bettype != "Over or Under Goals" || bettype != "Odd or Even" || bettype != "Over or Under Corners") {
                            for (var i = 0; i < betnames.length; i++) {

                                self.betoptions.push(this.selectedbets[iloop].betno + '. ' + betnames[i]);
                            }

                        }

                        //Simple 2 outcomes - two team outcomes only do need to do anything else - Half Time / Score First/ MoneyLine
                        if (bettype == "Score First " || bettype == "Moneyline" || bettype == "Tournament Bet") {
                            continue;
                        }

                        // short name of draw (when multiple bets)
                        var draw = "Draw (" + self.betoptions[self.betoptions.length - 2] + " - " + self.betoptions[self.betoptions.length - 1] + ")"

                        //Get Bet Type and Extra Bet Info
                        var bt = self.selectedbets[iloop].bettype;

                        bettype = bt.substring(0, bt.indexOf("~"));
                        var extrabetinfo = bt.substring(bt.indexOf("~") + 1, bt.length);

                        //Simple 3 outcomes - Match Odds / Half Time
                        if (bettype == "Match Odds" || bettype == "Half Time") {
                            self.betoptions.push(draw);
                            continue;
                        }

                        //Enter specific values for specific bets
                        if (bettype == "Over or Under Goals" || bettype == "Over or Under Corners") {
                            self.betoptions.push("Over~" + extrabetinfo);
                            self.betoptions.push("Under~" + parseFloat(extrabetinfo) * -1);
                            continue;
                        }

                        if (bettype == "Odd or Even") {
                            self.betoptions.push("Odd");
                            self.betoptions.push("Even");
                            continue;
                        }

                        //Add Values to teams
                        if (bettype == "Asian Handicaps" || bettype == "Handicaps") {
                            self.betoptions[0] = self.betoptions[0] + '~' + extrabetinfo;
                            self.betoptions[1] = self.betoptions[1] + '~' + parseFloat(extrabetinfo) * -1;
                            continue;
                        }

                        //Double Chance - oddity
                        if (bettype == "Double Chance") {
                            self.betoptions[0] = self.betoptions[0] + ' or draw';
                            self.betoptions[1] = 'draw or ' + self.betoptions[1];
                            self.betoptions.push(self.betoptions[0] + ' or ' + self.betoptions[1]);
                            continue;
                        }

                    } else {
                        //need to go and get teams associated with that competition outcome (need competitionid and outrightbettypeid)

                        var URL = process.env.VUE_APP_WEBAPI  + "FeatureTables.svc/TeamsforOutright";

                        axios.get(URL, {

                            params: {
                                CompetitionID: this.selectedbets[iloop].entityid,
                                OutRightBetTypeID: this.selectedbets[iloop].bettypeid
                            }

                        }).then(response => {

                            if (response.data == '') {

                                this.selectedbets.pop();

                                alert(`This competition currently has no competitors (with odds). If manually entered need deleting and reentering. So cannot be edited`);

                            } else {

                                let json = JSON.parse(response.data);

                                //convert json data into an array
                                json.forEach(function (bo) {
                                    self.betoptions.push(bo.betname);
                                });
                            }
                        },
                            (error) => { console.log(error) }
                        );
                    }
                }
            },

            //for settlement popup
            getGames: function () {

                var URL = process.env.VUE_APP_WEBAPI  + "FeatureTables.svc/SearchItems";

                return new Promise(resolve => {

                    axios.get(URL, {

                        params: {
                            desc: input,
                            noitems: 50,
                            wildcard: true,
                            sportid: -1
                        }

                    }).then(response => {


 let data = JSON.parse(response.data)[0];
                              if (Object.keys(data).length > 1) {
                data = data.BO;
              } else {
                data = [];
              }
              return resolve(data);
                    },

                        (error) => { console.log(error) }
                    );
                });

            },
            getResultValue: function (result) {


                var value = this.strip_html_tags(result.event);

                if (result.bettype != undefined) { value = value + ' (' + result.bettype + ')'; }

                return value
            },
            //auto complete component
            handleFocus() {
                this.focused = true
            },

            //auto complete component
            handleBlur(obj) {

                this.focused = false
            },

            valueColour(val) {
                let retVal = 'Black';
                if (val < 0) retVal = 'Red';
                if (val > 0) retVal = 'Green';
                return retVal;
            },

            //which settlement row has been clicked on so can save back if "winner" entered
            storeSettlementRow(id) {
                this.betresultid = id;
            },


            onSubmit(input) {

               

                this.betresults[this.betresultid].Winner = input.event;
            }

        }

    }


</script>


<style scoped>

    input[type="number"] {
        font-size: x-small
    }

    input[type="text"] {
        font-size: x-small
    }

    .highlight {
        background-color: gold;
    }

    .autocomplete-input-no-results.autocomplete-input-focused {
        border-bottom-color: transparent;
        border-radius: 8px 8px 0 0;
    }

    .autocomplete-input-no-results:not(.autocomplete-input-focused) ~ .autocomplete-result-list {
        display: none;
    }

.calwidth {
    width:100px;
    font-size:large;
}
</style>
